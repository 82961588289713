import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Panel, Collapse } from 'react-bootstrap';
import { useRequestData, useLabels } from 'wsm-common-data';
import { trackEvent } from 'ddc-track-event';

const LocationItem = ({ locationData, onChangeLocation, activeLocation }) => {
	const { deviceType } = useRequestData();
	const isDesktop = deviceType === 'DESKTOP';
	const labels = useLabels();
	const [isCollapsed, setCollapsed] = useState(true);
	const { widgetName, windowId } = useRequestData();
	const locationId = locationData.id;
	const onToggleCollapsed = () => {
		document
			.getElementById(`panel-collapse-location-list-${locationId}`)
			.removeAttribute('aria-expanded');
	};

	const handleCollapse = () => {
		setCollapsed(!isCollapsed);

		if (!isCollapsed) {
			trackEvent(widgetName, windowId, {
				action: 'collapsed',
				element: 'location-list accordion',
				result: 'Accordion collapsed'
			});
		} else {
			trackEvent(widgetName, windowId, {
				action: 'expanded',
				element: 'location-list accordion',
				result: 'Accordion expanded'
			});
		}
	};

	const radioValue = JSON.stringify({
		id: locationId,
		name: locationData.name
	});

	const panelClassNames = () => {
		if (activeLocation === radioValue) {
			return 'border-info';
		} else if (isDesktop) {
			return 'border-transparent';
		} else {
			return '';
		}
	};

	return (
		<Panel
			className={`location-list-group my-3 my-lg-4 mx-0 border border-radius-md p-4 p-lg-5 ${panelClassNames()}`}
			id={`panel-location-list-${locationId}`}
			expanded={!isCollapsed}
			onToggle={onToggleCollapsed}
		>
			<Panel.Heading role="presentation" className="position-relative">
				<Panel.Title className="d-flex align-items-center justify-content-between">
					<span className="d-flex align-items-center w-100 pr-3">
						<li className="radio m-0 w-100">
							<label
								className="d-flex align-items-center w-100"
								id={`label-location-item-${locationId}`}
								htmlFor={`radio-location-${locationId}`}
							>
								<input
									type="radio"
									id={`radio-location-${locationId}`}
									value={radioValue}
									checked={activeLocation === radioValue}
									onChange={(e) => onChangeLocation(e)}
								/>
								<span className="ml-4">
									<span data-cy="location-store-name">
										{locationData.name}, {locationData.city}
										, {locationData.state}
									</span>
									<br />
									<span
										className="ddc-font-size-xsmall text-muted"
										data-cy="location-store-distance"
									>
										{Math.round(locationData.distance) === 1
											? labels.get('1_MI_AWAY')
											: labels
													.get('X_MI_AWAY')
													.replace(
														'{distance}',
														Math.round(
															locationData.distance
														)
													)}
									</span>
								</span>
							</label>
						</li>
					</span>
					<span>
						<button
							type="button"
							aria-expanded={!isCollapsed}
							aria-controls={`panel-collapse-location-list-${locationId}`}
							id={`panel-title-location-list-${locationId}`}
							className="bg-none p-0 border-0 btn-detail-location ddc-font-size-xsmall text-muted text-decoration-underline"
							onClick={handleCollapse}
						>
							{isCollapsed
								? labels.get('DETAILS')
								: labels.get('CLOSE')}
						</button>
					</span>
				</Panel.Title>
			</Panel.Heading>
			<Collapse
				in={!isCollapsed}
				id={`panel-collapse-location-list-${locationId}`}
				role="region"
				aria-labelledby={`panel-title-location-list-${locationId}`}
				onEntered={onToggleCollapsed}
				onExited={onToggleCollapsed}
			>
				<div className="panel-collapse bg-none">
					<Panel.Body className="p-0">
						<div className="ddc-font-size-small mt-lg-5 mt-4">
							<div data-cy="location-store-address">
								{locationData.address1}
							</div>
							<div data-cy="location-store-city">
								{locationData.city}, {locationData.state}{' '}
								{locationData.postalCode}
							</div>
						</div>
					</Panel.Body>
				</div>
			</Collapse>
		</Panel>
	);
};

LocationItem.propTypes = {
	locationData: PropTypes.shape({
		address1: PropTypes.string,
		city: PropTypes.string,
		country: PropTypes.string,
		distance: PropTypes.number,
		id: PropTypes.string,
		name: PropTypes.string,
		postalCode: PropTypes.string,
		state: PropTypes.string,
		uom: PropTypes.string
	}),
	onChangeLocation: PropTypes.func,
	activeLocation: PropTypes.bool
};

export default LocationItem;
