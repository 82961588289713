import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRequestData, useLabels, useFlags } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { validateZip } from '../utilities/validate-zip';
import { getPostalFromCoords } from '../utilities/getGeoLocationFetcher';

const LocationInput = ({ postalCode, updatePostalCode }) => {
	const { deviceType } = useRequestData();
	const isDesktop = deviceType === 'DESKTOP';
	const labels = useLabels();
	const flags = useFlags();

	const [input, setInput] = useState(postalCode);
	const [isValidZip, setValidZip] = useState(validateZip(postalCode));
	const [isAlert, setAlert] = useState(false);
	const [shouldShowUseMyLocation, setShowUseMyLocation] = useState(true);
	const [isShareLocationAlert, setShareLocationAlert] = useState(false);
	const inputLabel = labels.get('YOUR_ESTIMATION_ZIP_CODE');

	useEffect(() => {
		const calculateShowUseMyLocation = async () => {
			const sharedUserLocation =
				sessionStorage.getItem('sharedUserLocation');
			const permission = await navigator?.permissions?.query({
				name: 'geolocation'
			});

			const isUsedSharedLocation =
				sharedUserLocation && sharedUserLocation === postalCode;
			setShowUseMyLocation(
				!isUsedSharedLocation && permission?.state !== 'denied'
			);
		};

		calculateShowUseMyLocation();
	}, [postalCode]);

	const handleInput = (e) => {
		const newInput = e.target.value;
		setValidZip(validateZip(newInput));

		if (newInput !== input) {
			setInput(newInput);
		}
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();

		if (e.key === 'Enter' || e.type === 'click') {
			if (isValidZip) {
				updatePostalCode(input);
				setAlert(false);
				setShareLocationAlert(false);
			} else {
				setAlert(true);
			}
		}
	};

	const onSuccessLocation = async (position) => {
		// Caching from previous shared location
		let postalFromCoords = sessionStorage.getItem('sharedUserLocation');

		if (!postalFromCoords) {
			const { coords } = position;
			postalFromCoords = await getPostalFromCoords(coords, flags);
		}

		if (!postalFromCoords) {
			setShareLocationAlert(true);
			return;
		}

		setInput(postalFromCoords);
		sessionStorage.setItem('sharedUserLocation', postalFromCoords);
		setValidZip(validateZip(postalFromCoords));
		setShowUseMyLocation(false);
	};

	const onErrorLocation = (geolocationPositionError) => {
		const { code } = geolocationPositionError;

		// PERMISISION DENIED
		// Read more: https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError#instance_properties
		if (code === 1) {
			setShowUseMyLocation(false);
			return;
		}

		setShareLocationAlert(true);
	};

	const handleBrowserLocationSharing = () => {
		// request HTML5 geolocation info
		if (navigator?.geolocation) {
			navigator?.geolocation.getCurrentPosition(
				onSuccessLocation,
				onErrorLocation
			);
		}
	};

	const form = (
		<form className="w-100 p-0 mt-4">
			<div className="d-flex w-100 p-0 align-items-stretch form-control-transparent">
				<input
					className={`form-control ${
						isAlert ? 'invalid' : ''
					} mr-3 h-auto`}
					type="search"
					aria-label={labels.get('ENTER_A_NEW_ZIP_CODE')}
					onChange={handleInput}
					onKeyUp={handleFormSubmit}
					placeholder={input}
					value={input}
				/>
				<button
					className={setClassNames([
						'btn',
						'btn-primary',
						'text-nowrap',
						isDesktop ? 'btn-sm' : ''
					])}
					type="submit"
					onClick={handleFormSubmit}
					disabled={!isValidZip}
					title={labels.get('UPDATE_ZIP')}
					aria-label={labels.get('UPDATE_ZIP')}
				>
					{labels.get('UPDATE_ZIP')}
				</button>
			</div>
		</form>
	);
	return (
		<div
			className={setClassNames([isDesktop ? 'ddc-font-size-xsmall' : ''])}
		>
			<h4 className="d-flex align-items-center mt-0 mb-4">
				<i
					className="ddc-icon ddc-icon-map-outline2 mr-3"
					aria-hidden="true"
				/>
				<span> {inputLabel}</span>
			</h4>
			{form}
			{isAlert && (
				<span className="d-block mt-1 text-danger">
					{labels.get('AUTOCOMPLETE_HELP_TEXT')}
				</span>
			)}
			{shouldShowUseMyLocation && (
				<>
					<button
						type="button"
						className="d-flex align-items-center ddc-font-size-small text-link text-decoration-none text-primary mt-4"
						onClick={handleBrowserLocationSharing}
					>
						<i
							className="ddc-icon ddc-icon-location-target mr-3"
							aria-hidden="true"
						/>
						<span className="text-decoration-underline">
							{labels.get('USE_MY_LOCATION')}
						</span>
					</button>

					{isShareLocationAlert && (
						<span className="d-block mt-1 text-danger">
							{labels.get('SORRY_ZIP_CODE_UNAVAILABLE')}
						</span>
					)}
				</>
			)}
		</div>
	);
};

LocationInput.propTypes = {
	postalCode: PropTypes.string.isRequired,
	updatePostalCode: PropTypes.func.isRequired
};

export default LocationInput;
