import { hysteriFetch, wrappers } from 'hysterics';
import logger from 'ws-scripts/modules/logger';

const fetcher = hysteriFetch('getLocationFetcherFn');

export const getPostalFromCoords = async ({ latitude, longitude }, flags) => {
	const apiURLParams = [
		'latitude',
		encodeURIComponent(latitude),
		'longitude',
		encodeURIComponent(longitude)
	].join('/');

	try {
		const response = await fetcher(
			`/apis/location-services/${apiURLParams}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			},

			{
				[wrappers.retry.contextKey]: {
					retries: flags['ws-inv-filters-postal-from-coords-retries']
				},
				[wrappers.timeout.contextKey]: {
					timeout: flags['ws-inv-filters-postal-from-coords-timeout']
				}
			}
		);

		const postalCode =
			Object.keys(response).length > 1
				? response.postalCode
				: response[0]?.zipcode;
		return postalCode;
	} catch (err) {
		logger.log(`Error retrieving location: ${err.message}`);
		return null;
	}
};
