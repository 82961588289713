import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels } from 'wsm-common-data';
import LocationInput from './LocationInput';
import LocationList from './LocationList';
import { trapFocus } from '../utilities/trapFocus';

const LocationModal = ({
	userPostalCode,
	title,
	isOpen,
	onClose,
	isMobile,
	closestDealers
}) => {
	const [animationName, setAnimationName] = useState(null);
	const [activeLocation, setActiveLocation] = useState(null);
	const labels = useLabels();
	const hideModal = () => {
		// Remove animation className and allow animation transition before removing modal
		setAnimationName(null);
		setTimeout(() => {
			onClose();
		}, 300);
	};

	const supportARIA = (modal) => {
		const modalWrap = modal.closest('[aria-hidden]');
		if (modalWrap) {
			modalWrap?.removeAttribute('aria-hidden');
		}
	};

	const onChangeLocation = (e) => {
		const locationValue = e.target.value;
		setActiveLocation(locationValue);
		window.sessionStorage.setItem('userStore', locationValue);
		window?.DDC?.pubsub?.publish('location-modal/update-store');
	};

	const getLocationStore = () => {
		const userStoreString = window.sessionStorage.getItem('userStore');
		if (userStoreString) {
			setActiveLocation(userStoreString);
		}
	};

	useEffect(() => {
		if (isOpen) {
			getLocationStore();
			let deActivateFocusTrap = null;

			// Add animation className after modal element is found
			const updateModal = () => {
				const modal = document.querySelector(
					'#location-modal.modal.dock-full'
				);
				if (modal) {
					supportARIA(modal);
					setTimeout(() => setAnimationName('slide'), 50);
					// eslint-disable-next-line no-use-before-define
					clearInterval(intervalModal);
					deActivateFocusTrap = trapFocus(modal);
				}
			};
			const intervalModal = setInterval(updateModal, 50);

			return () => {
				clearInterval();
				if (deActivateFocusTrap) {
					deActivateFocusTrap();
				}
			};
		}

		return () => {};
	}, [isOpen]);

	const updatePostalCode = (input) => {
		if (input !== userPostalCode) {
			setActiveLocation(null);
			window.DDC.InvData.setUserLocation({
				filterName: 'geoZip',
				filterValues: [input]
			});
			window.localStorage.setItem('userLocation', input);
		}
	};

	return (
		<React.Fragment>
			<Modal
				show={isOpen}
				animation={false}
				onHide={hideModal}
				className={setClassNames([
					isMobile ? 'dock-full' : '',
					animationName
				])}
				aria-modal="true"
				aria-labelledby="location-modal-label"
				id="location-modal"
			>
				<Modal.Body className="pb-0">
					<div className="d-flex position-relative">
						<Modal.Title
							id="location-modal-label"
							className={setClassNames([
								'w-100',
								isMobile ? '' : 'text-center',
								'ddc-font-size-large'
							])}
						>
							{title}
						</Modal.Title>
						<button
							type="button"
							id="hide-location-modal-button"
							className="position-absolute btn-sm h-100 text-link text-decoration-underline text-right"
							data-testid="header-hide-location-modal"
							onClick={hideModal}
						>
							{labels.get('DONE')}
						</button>
					</div>
					<hr className="modal-divider mb-5" />
					<div className="location-list-container px-lg-10">
						<LocationInput
							postalCode={userPostalCode}
							updatePostalCode={updatePostalCode}
						/>
						<hr className="modal-divider my-5 mx-0" />
						<LocationList
							closestDealers={closestDealers}
							onChangeLocation={onChangeLocation}
							activeLocation={activeLocation}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="pt-0 border-0 location-list-footer pb-lg-10 pr-lg-10 pl-lg-10">
					<button
						type="button"
						id="hide-location-modal-footer-button"
						data-testid="footer-hide-location-modal"
						className="btn btn-primary btn-block"
						onClick={hideModal}
					>
						{labels.get('DONE')}
					</button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	);
};

LocationModal.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	userPostalCode: PropTypes.string.isRequired,
	closestDealers: PropTypes.arrayOf(
		PropTypes.shape({
			address1: PropTypes.string,
			city: PropTypes.string,
			country: PropTypes.string,
			distance: PropTypes.number,
			id: PropTypes.string,
			name: PropTypes.string,
			postalCode: PropTypes.string,
			state: PropTypes.string,
			uom: PropTypes.string
		})
	)
};

export default LocationModal;
