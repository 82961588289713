import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';
import LocationItem from './LocationItem';

const LocationList = ({ closestDealers, onChangeLocation, activeLocation }) => {
	const labels = useLabels();
	const [isShowAll, setIsShowAll] = useState(false);

	const onShowAllLocation = () => {
		setIsShowAll(true);
	};

	const isEmptyStore =
		closestDealers.length === 0 ||
		(closestDealers.length > 0 &&
			(closestDealers[0].distance === null ||
				closestDealers[0].distance === undefined));

	return (
		<div>
			<h4 className="d-flex align-items-center">
				<i
					className="ddc-icon ddc-icon-store mr-3"
					aria-hidden="true"
				/>
				<span>{labels.get('CHOOSE_YOUR_STORE')}</span>
			</h4>
			<div>
				{!isEmptyStore ? (
					<>
						{closestDealers.map(
							(item, index) =>
								(index < 5 || isShowAll) && (
									<div
										key={`location-list-${item.id}-${item.distance}`}
									>
										<LocationItem
											activeLocation={activeLocation}
											locationData={item}
											onChangeLocation={onChangeLocation}
										/>
										<hr className="modal-divider ml-0 mr-0 d-none d-lg-block" />
									</div>
								)
						)}
						{closestDealers.length > 5 && !isShowAll && (
							<div className="text-center mt-5 mb-4">
								<button
									type="button"
									className="text-muted btn-sm h-100 text-link text-decoration-underline text-right"
									onClick={onShowAllLocation}
								>
									{labels.get('SHOW_ALL_LOCATIONS')}
								</button>
							</div>
						)}
					</>
				) : (
					<div className="py-5 text-center">
						{labels.get('SORRY_NO_STORES_AVAIL')}
					</div>
				)}
			</div>
		</div>
	);
};

LocationList.propTypes = {
	closestDealers: PropTypes.arrayOf(
		PropTypes.shape({
			address1: PropTypes.string,
			city: PropTypes.string,
			country: PropTypes.string,
			distance: PropTypes.number,
			id: PropTypes.string,
			name: PropTypes.string,
			postalCode: PropTypes.string,
			state: PropTypes.string,
			uom: PropTypes.string
		})
	),
	onChangeLocation: PropTypes.func,
	activeLocation: PropTypes.bool
};

export default LocationList;
